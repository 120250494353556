<template>
  <section class="detail-page-ecv">
    <v-container class="mt-20 pa-md-0 pa-6">
      <p class="text-h3 pb-8 main-title">ECV</p>
      <div class="d-flex flex-row flex-wrap justify-md-space-between mt-10 mb-15">
        <p class="mb-md-0 mb-5">
          伊雲谷數位科技是一個提供雲端服務協助企業數位轉型的科技公司，在亞太地區已在十個國家有據點，並且持續擴張中
        </p>
        <div class="text-md-end text-start align-self-end">
          <p class="mb-1">WEB DESIGN & CMS</p>
          <p class="mb-0">2022</p>
        </div>
      </div>
    </v-container>
    <div class="mb-10">
      <v-img
        :src="require('@/assets/img/detail/ecv/landpage.png')"
        class="main-img"
      ></v-img>
    </div>

    <v-container>
      <v-row class="step-card">
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="step-card__number">01</div>
          <div class="pa-md-5 pa-3">
            <h1 class="step-card__title">需求與痛點</h1>
            <p class="step-card__content">
              伊雲谷想要進行網站改版，建立一個 「全球各分公司自行維護
              『官網資料』，受到總公司控管、審查」
              的前後台管理系統舊版官網資料龐大，網站的架構複雜，造成網頁的瀏覽速度不佳，因此網站的速度提升是一大訴求
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <v-img
            class="d-block step-card__img img-border-radius"
            src="@/assets/img/detail/ecv/desktop/s1.png"
            width="100%"
            
          ></v-img>
        </v-col>
      </v-row>

      <v-row class="step-card flex-md-row flex-column-reverse">
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <v-img
            class="d-block step-card__img img-border-radius"
            src="@/assets/img/detail/ecv/desktop/s2.png"
            width="100%"
          ></v-img>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="step-card__number">02</div>
          <div class="pa-md-5 pa-3">
            <h1 class="step-card__title">設計概念</h1>
            <p class="step-card__content">
              此次的設計理念是以企業logo中雲服務的雲作為元素發想，波浪紋能帶給user直覺的聯想，並運用毛玻璃與企業主色的藍色搭配，營造似天空中雲霧感。
              在網站中各頁面一致性的運用波浪紋，呈現乾淨、整齊的畫面，傳達企業的專業與品質。
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex flex-column align-center justify-center">
            <div class="step-card__number">03</div>
            <div class="my-10">
              <h1 class="step-card__title">構思與解決方案</h1>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="pa-md-10 pa-3 d-flex flex-column justify-center align-center" >
          <div class="pa-md-5 pa-3 mb-10 text-center">
            <h2 class="mb-10 detail-subtitle">資訊架構規劃</h2>
            <p class="detail-content">
              雙方多次來回確認需求，依照伊雲谷的組織架構與未來使用性，我們歸納出此次專案最複雜的三個重點功能
            </p>
          </div>
        </v-col>
        <v-col
          cols="12" md="6"
          class="pa-md-10 pa-3 d-flex flex-column justify-center align-center"
        >
          <div class="pa-md-5 pa-3">
            <h2 class="mb-10 detail-subtitle">分站管理機制</h2>
            <p class="detail-content">
              伊雲谷的各站分公司有各自管理網站內容的需求，而非同一內容直接翻譯為多個語言，因此在網站架構中，所有資料我們皆以分站為一單位，且不會互相共用，各自管理分站的分站網址、SEO、語言等等。
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="my-10">
            <v-img
              class="d-block img-border-radius"
              position="top left"
              src="@/assets/img/detail/ecv/desktop/s3.png"
              max-width="737"
              max-height="764"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mb-10 mt-20 d-flex flex-md-column flex-column-reverse">
      <v-img class="img-border-radius"
        container
        :src="require('@/assets/img/detail/ecv/desktop/investigate-module.png')"
      ></v-img>
      <v-row>
        <v-col cols="12" class="pa-md-10 pa-3">
          <div class="pa-md-5 pa-3 center-content">
            <h2 class="mb-10 detail-subtitle">內容審核機制</h2>
            <p class="detail-content">
              因所有內容皆須經過控管與審核，資料的審核狀態成為規劃中首要考量的重點。我們在資料架構中將資料分為兩個區域，一為草稿區，即尚未經過審核的內容，二為正式資料區，當已通過審核，會由草稿區轉至正式資料區，然而前台所看到的畫面皆為在正式資料區的內容；資料在正式資料區後，仍有修改調整的可能性，所以再次編輯並審核後也能再次覆蓋正式資料區的內容。
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="d-flex flex-md-row flex-column-reverse">
        <v-col cols="12" md="6" class="pa-md-10 pa-3">
          <div class="my-10">
            <v-img
              class="d-block img-border-radius"
              position="center center"
              contain
              src="@/assets/img/detail/ecv/desktop/s4.png"
              max-width="737"
              max-height="721"
              ratio="1"
            ></v-img>
          </div>
        </v-col>
        <v-col
          cols="12" md="6"
          class="pa-md-10 pa-3 d-flex flex-column justify-center align-center"
        >
          <div class="pa-md-5 pa-3 mb-10">
            <h2 class="mb-10 detail-subtitle">權限管理機制</h2>
            <p class="detail-content">
              因應各站獨自維護資料，站點之間不互相共用的情境下，後台管理員權限顯得特別重要，儘管後台使用者皆為同一公司員工，但因加入分站管理與審核機制，不同階層、分站、責任職位的管理員看到與操作的資料需有區分，以便後續維護與管理。我們透過建立群組的方式，區分各個群組可以維護的分站、頁面以及是否可以審核或編輯。
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <section class="my-10">
      <div class="d-flex flex-column flex-md-row">
        <div class="design-block__img mr-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/ecv/desktop/d1.png')"
          ></v-img>
        </div>
        <div class="design-block__img ml-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/ecv/desktop/d3.png')"
          ></v-img>
        </div>
      </div>
      <!-- ---------------------- -->
      <div class="d-flex flex-column flex-md-row">
        <div class="design-block__img mr-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/ecv/desktop/d2.png')"
          ></v-img>
        </div>
        <div class="design-block__img ml-md-1 mb-2">
          <v-img
            :src="require('@/assets/img/detail/ecv/desktop/d4.png')"
          ></v-img>
        </div>
      </div>
      <!-- ---------------------- -->
      <div class="d-flex justify-center align-center ecv-gifbox">
        <v-img
          max-width="1200"
          height="auto"
          width="100%"
          contain
          :src="require('@/assets/img/detail/ecv/ecv-home.gif')"
        ></v-img>
      </div>
    </section>

    <!-- <v-container>
      <div class="d-flex justify-space-between mt-20 mb-15">
        <p class="mb-0 text-h3">Tech</p>
        <div class="d-flex align-self-end">
          <p class="mb-0">網站使用的技術</p>
        </div>
      </div>

      <v-row class="pa-md-10 pa-3 mt-15">
        <v-col v-for="(tool, i) in tools" :key="i" cols="12" md="2">
          <v-img max-width="250" class="ma-auto" :src="tool.src" />
        </v-col>
      </v-row>
    </v-container> -->

    <!-- <v-container class="discover-project mb-20">
      <div class="d-flex justify-space-between mt-20 mb-15">
        <p class="mb-0 text-h3">Discover More Projects</p>
      </div>

      <v-row>
        <v-col>
          <v-img
            max-width="100%"
            width="100%"
            contain
            class="discover-project__img"
            :src="require('@/assets/img/works/HakkeTV-s.png')"
          />
        </v-col>
        <v-col>
          <div
            style="height: 100%"
            class="d-flex flex-column justify-space-between"
          >
            <p class="primary--text mt-5">
              客家電視台官方網站與APP，產品內容包含隨選影音、複合新聞及官方資訊，同時建置自動影音側錄系統，解決影音上架人手不足的問題。
            </p>
            <div class="d-flex justify-end align-center mb-8">
              <div class="mr-5">
                <p class="text-h6 mb-0">
                  HakkeTV <span class="mx-2">2021 | OTT</span>
                </p>
              </div>
              <div>
                <div
                  v-ripple
                  class="
                    discover-project__arrow-btn
                    white--text
                    mr-3
                    pa-md-5 pa-3
                    pointer
                  "
                >
                  <v-img
                    width="35"
                    :src="require('@/assets/img/icon/arrow.svg')"
                  ></v-img>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container> -->

    <!-- Talk -->
    <Talk class="mb-4 mt-3" />
    <!-- footer -->
    <Footer />
  </section>
</template>

<script>
import PlanDesign from "@/components/detail/planDesign.vue";
import BrainStorm from "@/components/detail/brainStorm.vue";
import scrollbar from "@/views/scrollbar.js";
import Talk from "@/components/talk.vue";
import Footer from "@/components/footer.vue";
export default {
  mixins: [scrollbar],
  components: {
    PlanDesign,
    BrainStorm,
    Talk,
    Footer,
  },
  data: () => ({
    tools: [
      { src: require("@/assets/img/tool/1.png") },
      { src: require("@/assets/img/tool/2.png") },
      { src: require("@/assets/img/tool/3.png") },
      { src: require("@/assets/img/tool/4.png") },
      { src: require("@/assets/img/tool/5.png") },
      { src: require("@/assets/img/tool/7.png") },
    ],
  }),
  computed: {
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  async mounted() {
    this.onIdeateImgLoad();
  },
  methods: {
    onIdeateImgLoad() {
      const image = $(".ideateImg")[0];
      image.onload = () => {
        this.$target = $(".ideateImg-wrap");
        this.initScrollbar();
      };
    },
  },
};
</script>